import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Car from './Car';
import Checkout from './Checkout';

const CarService = () => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const selectedType = getQueryParam('type');

  useEffect(() => {
    localStorage.setItem('selectedServices', JSON.stringify(selectedServices));
  }, [selectedServices]);

  const services = {
    hatchback: [
      {
        id: 1,
        name: 'Foam Wash',
        price: 25,
        image: '/images/professional.jpg',
        services: [
          "Foam exterior wash",
          "Interior vacuum clean",
          "Mats cleaning & glass cleaning",
          "Engine & underbody cleaning"
        ],
      },
      {
        id: 2,
        name: 'Dry Wash',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
      {
        id: 3,
        name: 'Polishing',
        price: 30,
        image: '/images/professional.jpg',
        services: ['Exterior polishing', 'Scratch removal', 'Gloss finish'],
      },
      {
        id: 4,
        name: 'Deep Cleaning',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
    ],
    suv: [
      {
        id: 1,
        name: 'Foam Wash',
        price: 25,
        image: '/images/professional.jpg',
        services: [
          "Foam exterior wash",
          "Interior vacuum clean",
          "Mats cleaning & glass cleaning",
          "Engine & underbody cleaning"
        ],
      },
      {
        id: 2,
        name: 'Dry Wash',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
      {
        id: 3,
        name: 'Polishing',
        price: 30,
        image: '/images/professional.jpg',
        services: ['Exterior polishing', 'Scratch removal', 'Gloss finish'],
      },
      {
        id: 4,
        name: 'Deep Cleaning',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
    ],
    sedan: [
      {
        id: 1,
        name: 'Foam Wash',
        price: 25,
        image: '/images/professional.jpg',
        services: [
          "Foam exterior wash",
          "Interior vacuum clean",
          "Mats cleaning & glass cleaning",
          "Engine & underbody cleaning"
        ],
      },
      {
        id: 2,
        name: 'Dry Wash',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
      {
        id: 3,
        name: 'Polishing',
        price: 30,
        image: '/images/professional.jpg',
        services: ['Exterior polishing', 'Scratch removal', 'Gloss finish'],
      },
    ],
    bike: [
      {
        id: 1,
        name: 'Bike Wash',
        price: 25,
        image: '/images/professional.jpg',
        services: [
          "Foam exterior wash",
          "Interior vacuum clean",
          "Mats cleaning & glass cleaning",
          "Engine & underbody cleaning"
        ],
      },
      {
        id: 2,
        name: 'Bike Dry Wash',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
      {
        id: 3,
        name: 'Bike Polishing',
        price: 30,
        image: '/images/professional.jpg',
        services: ['Exterior polishing', 'Scratch removal', 'Gloss finish'],
      },
      {
        id: 4,
        name: 'Bike Deep Cleaning',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
    ],
    scooter: [
      {
        id: 1,
        name: 'Scooter Wash',
        price: 25,
        image: '/images/professional.jpg',
        services: [
          "Foam exterior wash",
          "Interior vacuum clean",
          "Mats cleaning & glass cleaning",
          "Engine & underbody cleaning"
        ],
      },
      {
        id: 2,
        name: 'Scooter Dry Wash',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
      {
        id: 3,
        name: 'Scooter Polishing',
        price: 30,
        image: '/images/professional.jpg',
        services: ['Exterior polishing', 'Scratch removal', 'Gloss finish'],
      },
      {
        id: 4,
        name: 'Scooter Deep Cleaning',
        price: 20,
        image: '/images/professional.jpg',
        services: ['Waterless cleaning', 'Wax polish', 'Interior vacuuming'],
      },
    ],
  };

  const handleSelect = (service) => {
    if (selectedServices.some(s => s.id === service.id)) {
      setSelectedServices(selectedServices.filter(s => s.id !== service.id));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  const handleBooking = () => {
    if (selectedServices.length === 0) {
      setError('Please select at least one service before proceeding.');
      return;
    }
    localStorage.setItem('selectedServices', JSON.stringify(selectedServices));
    navigate('/booking');
  };

  return (
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
        {services[selectedType]?.map((service) => (
          <Car style={{ flex: 1, margin: '10px' }} key={service.id} service={service} onSelect={handleSelect} />
        ))}
      </div>
      {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
      <Checkout selectedServices={selectedServices} onBooking={handleBooking} />
    </div>
  );
};

export default CarService;
