import React, { useState } from 'react';
import './LoveUs.css';

const reasons = [
    { title: 'Eco-Friendly', description: 'We use waterless technology to save water and reduce environmental impact.', image: '/images/1.jpg' },
    { title: 'Convenient', description: 'Our doorstep service means you can get your car washed without leaving your home or office.', image: '/images/convenient.jpg' },
    { title: 'Professional', description: 'Our trained professionals use the best products and techniques to ensure your car looks its best.', image: '/images/professional.jpg' },
    { title: 'Affordable', description: 'We offer competitive pricing without compromising on quality.', image: '/images/saving.jpg' },
];

const LoveUs = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrev = () => {
        setCurrentIndex(currentIndex === 0 ? reasons.length - 3 : currentIndex - 1);
    };

    const goToNext = () => {
        setCurrentIndex(currentIndex === reasons.length - 3 ? 0 : currentIndex + 1);
    };

    return (
        <section className="love-us">
            <h2 className='text-center mystic-content heading'>Why Customers Love Us</h2>
            <div className="reasons-slider">
                <div className="reasons" style={{ transform: `translateX(-${currentIndex * (100 / 3)}%)` }}>
                    {reasons.map((reason, index) => (
                        <div key={index} className="reason">
                            <img src={reason.image} alt={reason.title} />
                            <h4>{reason.title}</h4>
                            <p>{reason.description}</p>
                        </div>
                    ))}
                </div>
                <button className="prev-btn" onClick={goToPrev}>&#10094;</button>
                <button className="next-btn" onClick={goToNext}>&#10095;</button>
            </div>
        </section>
    );
};

export default LoveUs;
