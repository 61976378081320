import React from 'react';
import './home.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './imageCarousel.css';
import { Fade } from 'react-reveal';

const ImageCarousel = () => {
  return (
    <div className="banner">
      <div className="banner-image">
        <img src="./images/carousel/car.gif" alt="Banner" />
      </div>
      <div className="banner-content">
        <Fade cascade>
          <h1 className="banner-title">
            "Your car, our care, right at your doorstep."
          </h1>
          <h3 className="banner-subtitle">
            "Immerse yourself in the excellence of our doorstep car washing services"
          </h3>
          <p className="banner-text">
            <strong>Feel free to Explore!!!! </strong>
          </p>
          
        </Fade>
      </div>
    </div>
  );
};

export default ImageCarousel;
