import React from 'react'
import styled from 'styled-components'
import {useNavigate  } from 'react-router-dom';
const Login = () => {
    const navigate = useNavigate();
    const handleClick = () => {
      navigate('/admin/Setting');
    };
  return (
    <Section>
      <div className='container login-frm'>
            <div className='row'>
                <div className='col-md-5 m-auto'>
                    <form>
                        <div className='mb-5'>
                            <h3 className='text-center text-white'>लॉग इन करें</h3>
                            <p className='text-center text-white'>अपना मोबाइल नंबर और पासवर्ड भरे</p>
                        </div>
                        <div className="mb-2">
                            <input type="email" className="form-control" style={{height:'8vh'}} id="exampleInputEmail1" placeholder='मोबाइल नंबर डाले'/>
                        </div>
                        <div className="mb-2">
                            <input type="password" className="form-control" style={{height:'8vh'}}  id="exampleInputPassword1" placeholder='पासवर्ड डाले'/>
                        </div>
                        <div className='check-box'>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="policy"/>
                                <label className="form-check-label" for="policy">मुझे नियम और शर्तें स्वीकार्य हैं</label>
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="remberme"/>
                                <label className="form-check-label" for="remberme">कृपया मुझे याद रखे</label>
                            </div>
                        <button type="submit" className="btns btn-2 btn-full" onClick={handleClick}>प्रवेश करे</button>
                        </div>
                    </form>
                </div>
            </div>
      </div>
    </Section>
  )
}

const Section=styled.div`
    .login-frm{
        width: 100%;
        height: 100vh;
        display: grid;
    }
    .login-frm form{
        background: #ffffffbf;
        padding: 15px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }
    .check-box {
        width: 60%;
        padding: 10px;
        position: relative;
        left: 15%;
        color:#E09C2B;
        font-weight: bolder;
    }
   
`
export default Login
