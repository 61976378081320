import React from 'react';
import styled from 'styled-components';

const Checkout = ({ selectedServices, onBooking }) => {
  const totalAmount = selectedServices.reduce((acc, service) => acc + service.price, 0);

  return (
    <CheckoutContainer>
      <TotalAmount>Total Amount: ₹{totalAmount}</TotalAmount>
      <CheckoutButton className='button1' onClick={onBooking}>Book Now</CheckoutButton>
    </CheckoutContainer>
  );
};

const CheckoutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 5px;
`;

const TotalAmount = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const CheckoutButton = styled.button`
  background-color: #008CBA;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #007BAC;
  }
`;

export default Checkout;
