import React, { useState } from 'react';
import styled from 'styled-components';

const Car = ({ service, onSelect }) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleSelect = () => {
    setIsSelected(!isSelected);
    onSelect(service);
  };

  return (
    <Card>
      <Image src={service.image} alt={service.name} />
      <CardContent>
        <ServiceName>{service.name}</ServiceName>
        <Price>Price: ₹{service.price}</Price>
        <ServiceList>
          {service.services.map((s, index) => (
            <li key={index}>{s}</li>
          ))}
        </ServiceList>
        <SelectButton isSelected={isSelected} onClick={handleSelect}>
          {isSelected ? 'Selected' : 'Select'}
        </SelectButton>
      </CardContent>
    </Card>
  );
};

const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 20px;
`;

const ServiceName = styled.h3`
  font-size: 18px;
  margin: 0;
`;

const Price = styled.p`
  font-size: 16px;
  margin: 10px 0;
`;

const ServiceList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  li {
    margin-bottom: 5px;
  }
`;

const SelectButton = styled.button`
  background-color: ${(props) => (props.isSelected ? '#4CAF50' : '#008CBA')};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: ${(props) => (props.isSelected ? '#45a049' : '#007BAC')};
  }
`;

export default Car;
