import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';


const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <h4>About Us</h4>
                    <p>Klean on Veel is a premier door-step car washing company dedicated to providing exceptional car care services at your convenience.</p>
                </div>
                <div className="footer-section">
                    <h4>Navigation</h4>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#services">Services</a></li>
                        <li><a href="#about">About Us</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Contact Us</h4>
                    <p>Email: kleanonveel@gmail.com</p>
                    <p>Phone: +91 9911311361</p>
                    <div className="social-icons">
                        <a href="https://www.facebook.com"><FaFacebookF /></a>
                        <a href="https://www.twitter.com"><FaTwitter /></a>
                        <a href="https://www.instagram.com"><FaInstagram /></a>
                        <a href="https://www.linkedin.com"><FaLinkedin /></a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Klean on Veel. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;