import { createGlobalStyle } from "styled-components";
export const Style = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
:root{
}

.btns{
  border-radius: 30px;
  padding: 10px;
  border: none;
  width:auto
}

.footer-btns{
  width : 40px;
  height  : 40px;
  padding:0px 0px;
  border-radius: 35px;
  font-size: 18px;
  color:White;
  border:1px solid White;
  text-align: center;
}
.btn-1 {
  background: #fefefe99;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.btn-2 {
  background: #E09C2B;
}
.glry-btns{
  width:15%;

}
.btn-full{
  width:100%;
}
.btn-half{
  width:50%;
}
input,textarea,select {
  overflow: visible;
  border-radius: 25px !important;
}
.hide{
  display:none;
}
`;