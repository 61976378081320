import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Booking = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [serviceDate, setServiceDate] = useState('');
  const [selectedServices, setSelectedServices] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const services = JSON.parse(localStorage.getItem('selectedServices'));
    if (services) {
      setSelectedServices(services);
    }
  }, []);

  const generateOrderId = () => {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000);
    return `ORDER-${timestamp}-${randomNum}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};

    if (!name) newErrors.name = 'Name is required';
    if (!phone) newErrors.phone = 'Phone is required';
    if (!address) newErrors.address = 'Address is required';
    if (!serviceDate) newErrors.serviceDate = 'Service date is required';

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const orderId = generateOrderId();
    console.log("Booking details: ", name, phone, address, email, comment, selectedServices, serviceDate);

    const data = {
      name: name,
      phone: phone,
      address: address,
      email: email,
      comment: comment,
      service_date: serviceDate,
      service_name: selectedServices.map(service => service.name).join(', '),
      orderId: orderId
    };

    try {
      await axios.post('https://kleanonveel.com/api/order.php', data);
      setName('');
      setPhone('');
      setAddress('');
      setEmail('');
      setComment('');
      setServiceDate('');
      navigate('/order-success', { state: { orderId } });
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  // Calculate the minimum date for the service date picker
  const calculateMinDate = () => {
    const today = new Date();
    today.setDate(today.getDate());
    return today.toISOString().split('T')[0]; // Format date to YYYY-MM-DD
  };

  return (
    <Section>
      <div className="container mt-5">
        <div className="left-section">
          <h2 style={{borderBottom:"5px solid #0FA4AF"}}>Your Order</h2>
          <div className="service-card">
            {selectedServices.map((service, index) => (
              <div key={index} className="">
                <h4>{service.name}</h4>
                <p>{Array.isArray(service.services) ? service.services.join(', ') : service.services}</p>
              </div>
            ))}
            {!selectedServices.length && (
              <p>No services selected.</p>
            )}
          </div>
          <div class="company-summary">
  <h2>How Klean on Veel Works</h2>
  <p><strong>Klean on Veel</strong> is a premium doorstep car cleaning service that offers a seamless and convenient experience for its customers. Simply submit your details and select a preferred service date online. Within 15 minutes, our team will contact you to confirm the appointment, ensuring that your car cleaning needs are met right at your doorstep.</p>
</div>
        </div>
        <div className="right-section">
          <form onSubmit={handleSubmit}>
            <h3>Send Your Details</h3>
            <div className="form-group">
              <input
                type="text"
                className={`form-control ${errors.name ? 'error' : ''}`}
                placeholder='Name'
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </div>
            <div className="form-group">
              <input
                type="number"
                className={`form-control ${errors.phone ? 'error' : ''}`}
                placeholder='Phone'
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
              {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
            </div>
            <div className="form-group">
              <input
                type="text"
                className={`form-control ${errors.address ? 'error' : ''}`}
                placeholder='Address'
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
              {errors.address && <ErrorMessage>{errors.address}</ErrorMessage>}
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder='Email'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            {/* <div className="form-group">
              <textarea
                className="form-control"
                placeholder='Comment'
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
            </div> */}
            <div className="form-group">
              <label htmlFor="serviceDate">Choose your booking date:</label>
              <input
                type="date"
                className={`form-control ${errors.serviceDate ? 'error' : ''}`}
                placeholder='Service Date'
                min={calculateMinDate()}
                onChange={(e) => setServiceDate(e.target.value)}
                value={serviceDate}
              />
              {errors.serviceDate && <ErrorMessage>{errors.serviceDate}</ErrorMessage>}
            </div>
            <button type="submit" className="button1">Submit</button>
          </form>
        </div>
      </div>
    </Section>
  );
};

const Section = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  .left-section {
    width: 60%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .right-section {
    width: 35%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-control {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
  .button1 {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-top:10%;
  }
  .button1:hover {
    background-color: #0056b3;
  }
    .company-summary {
  font-family: 'Arial, sans-serif';
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
}

.company-summary h2 {
  font-family: 'Georgia, serif';
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
  text-align: center;
}

.company-summary p {
  font-size: 16px;
  line-height: 1.6;
  color: #34495e;
}

.company-summary strong {
  color: #e74c3c;
}

`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

export default Booking;
