import React from 'react'
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom'
import { Style } from './Style'
import { ThemeProvider } from 'styled-components';
// import NotFound from './pages/NotFound';
// import User from './layouts/User';
import Home from './pages/Home';
import Login from './pages/Login';
// import Admin from './layouts/Admin';
// import Setting from './pages/Setting';
// import MainFrm from './pages/MainFrm';
// import Custom from './layouts/Custom';
// import Gallery from './pages/Gallery';
// import OverviewPage from './pages/OverviewPage';
// import Complaint from './pages/Complaint';
// import UserDesktop from './pages/UserDesktop';
// import UserMoney from './pages/UserMoney';
// import PaymentPage from './pages/PaymentPage';
// import ConfirmPayment from './pages/ConfirmPayment';
// import ConfirmPayment2 from './pages/ConfirmPayment2';
// import SubAdmin from './pages/SubAdmin';
// import ReturnLogin from './pages/ReturnLogin';
// import QandA from './pages/QandA';
// import FirstLayout from './layouts/FirstLayout';
import Header from './pages/Header';
import CarService from './pages/CarService';
import Booking from './pages/Booking';
import OrderSuccess from './pages/Ordersuccess';
import Chatbot from './pages/Chatbot';
import AboutUs from './pages/AboutUs';
import Footer from './pages/Footer';
import New from './pages/New';

const App = () => {
  const theme = {
    colors: {
      heading: "rgb(24 24 29)",
      text: "rgba(29 ,29, 29, .8)",
      white: "#fff",
      black: " #212529",
      helper: "#8490ff",

      bg: "#F6F8FA",
      footer_bg: "#0a1435",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    media: {
      mobile: "768px",
      tab: "998px",
    },
  };
  return (
    <>
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        
        <Style/>
        <Routes>
          
          <Route >
              <Route exact path='/' element={<Home/>}/>
              <Route exact path="/carservice" element={<CarService />}/>
              <Route exact path="/About-us" element={<AboutUs />}/>
              <Route exact path='/booking' element={<Booking/>}/>
              <Route exact path='/login' element={<Login/>}/>
              <Route exact path='/new' element={<New/>}/>
              
              <Route path="/order-success" element={<OrderSuccess />} />
          </Route>
        </Routes>
        <Chatbot/>
        <Footer/>
      </Router>
      </ThemeProvider>
    </>
  )
}

export default App
