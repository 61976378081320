import React from "react";
import Countdown from "react-countdown";

export default function MyTimer() {
  const targetDate = new Date("2024-01-21T12:00:00Z");
  const indianDateTime = new Date(targetDate.getTime() + 5.5 * 60 * 60 * 1000);

  const formatTime = (value) => {
    const stringValue = value.toString().padStart(2, "0");
    if (stringValue.length === 2) {
      return stringValue.split("").map((digit, index) => (
        <div key={index} className="digit-box">
          {digit}
        </div>
      ));
    }
    return value;
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
     
      return (
        <div className="" style={{marginTop:"-15%", position:"absolute", width:"45%"}} >
          <div className="timer-content" style={{background:"#2a2d34"}}>
            <p>
              Get your CAR CLEANING Journey <span>Start!!</span>
            </p>
          </div>
          <div className="timer-inner">
            
              <img src="./images/carousel/frog.png" style={{width:"20%"}}/>
              
            
            <a type="submit" href="#book" className="anchor" style={{width:"50%", marginLeft:""}} >
            BOOK US
          </a>
          
            
              <img src="./images/carousel/frog.png" style={{width:"20%", transform: 'scaleX(-1)', marginLeft:""}}/>
              
            
            
          </div>
        </div>
      );
    
  };

  return (
    <div className="timer-area">
      <Countdown date={indianDateTime} renderer={renderer} />
    </div>
  );
}
