import React, { useState } from 'react';
import { FaCommentDots } from 'react-icons/fa';
import './Chatbot.css';

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleInput = (e) => {
        setInput(e.target.value);
    };

    const handleSend = () => {
        if (input.trim() !== '') {
            setMessages([...messages, { text: input, user: true }]);
            // Simulate a bot response
            setTimeout(() => {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: `Hi Hope you are doing GREAT!! you can connect us on Whatsapp: +91 9911311361`, user: false }
                ]);
            }, 1000);
            setInput('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default behavior of Enter key
            handleSend();
        }
    };

    return (
        <div className="chatbot">
            <button className="chatbot-button" onClick={handleToggle}>
                <FaCommentDots />
            </button>
            {isOpen && (
                <div className="chatbot-window">
                    <div className="chatbot-header">
                        <h4>Chat with us!</h4>
                        <button className="chatbot-close" onClick={handleToggle}>X</button>
                    </div>
                    <div className="chatbot-messages">
                        {messages.map((msg, index) => (
                            <div key={index} className={`chatbot-message ${msg.user ? 'user' : 'bot'}`}>
                                {msg.text}
                            </div>
                        ))}
                    </div>
                    <div className="chatbot-input">
                        <input
                            type="text"
                            value={input}
                            onChange={handleInput}
                            onKeyPress={handleKeyPress}
                            placeholder="Type a message..."
                        />
                        <button onClick={handleSend}>Send</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
