import React, { useState } from 'react'
import styled from 'styled-components'
import {useNavigate  } from 'react-router-dom';
import axios from 'axios';
const ContactUs = () => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState()
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [comment, setComment] = useState('')
    const navigate = useNavigate();
    const handleClick = () => {
      navigate('/admin/Setting');
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log("contact us logs are here: ", name,phone,address,email,comment)
        let data = {
            name:name,
            phone:phone,
            address: address,
            email: email,
            comment:comment
        }

        axios.post('https://kleanonveel.com/api/contactus.php', data).then((response)=>{
            console.log(response);
            setName('');
            setPhone('');
            setAddress('');
            setEmail('');
            setComment('');
        })

        // const formData = new FormData(event.target);
        // const data = Object.fromEntries(formData.entries());

        // Here, you'll use either the Google Sheets API or Zapier to send the data
        // For example, using the Google Sheets API:
        // const auth = new google.auth.GoogleAuth({
        //     keyFile: 'path/to/your/service-account-key.json',
        //     scopes: ['https://www.googleapis.com/auth/spreadsheets'],
        // });
        // const sheets = google.sheets({ version: 'v4', auth });
        // await sheets.spreadsheets.values.append({
        //     spreadsheetId: 'your-spreadsheet-id',
        //     range: 'Sheet1',
        //     valueInputOption: 'RAW',
        //     resource: {
        //         values: [
        //             [data.name, data.phone, data.address, data.email, data.comment],
        //         ],
        //     },
        // });
    }
  return (
    <Section >
        <img src='./images/connectus.png' style={{height:"70%", width:"30%", marginTop:"5%"}}></img>
      <div className='container login-frm mb-5'>
        
            <div className='row'>
               
                    <form action="https://forms.gle/S9uK1s2g35A9P5EP7" method="POST" onSubmit={handleSubmit}>
                        <div className='mb-5'>
                            <h3 className='text-center mystic-content heading'><strong>Connect us</strong></h3>
                            
                        </div>
                        
                            <div className='d-flex'>
                        <div className="mb-2" style={{marginRight:'2%', width:"50%"}}>
                            <input type="text" className="form-control" style={{height:'8vh'}} id="" placeholder='Name' onChange={(e)=>setName(e.target.value)} value={name}/>
                        </div>
                        <div className="mb-2"style={{marginRight:'2%', width:"50%"}}>
                            <input type="number" className="form-control" style={{height:'8vh'}}  id="exampleInputPassword1" placeholder='Phone ' onChange={(e)=>setPhone(e.target.value)} value={phone}/>
                        </div>
                        </div>
                        <div className='d-flex'>
                        <div className="mb-2" style={{marginRight:'2%', width:"50%"}}>
                            <input type="text" className="form-control" style={{height:'8vh'}} id="exampleInputEmail1" placeholder='Address' onChange={(e)=>setAddress(e.target.value)} value={address}/>
                        </div>
                        <div className="mb-2" style={{marginRight:'2%', width:"50%"}}>
                            <input type="email" className="form-control" style={{height:'8vh'}} id="exampleInputEmail1" placeholder='Email' onChange={(e)=>setEmail(e.target.value)} value={email}/>
                        </div>
                        </div>
                        
                        <div className="mb-2">
                            <input type="text" className="form-control" style={{height:'20vh', wordWrap:"break-word", overflow:""}} id="exampleInputEmail1" placeholder='Message' onChange={(e)=>setComment(e.target.value)} value={comment}/>
                        </div>
                        <button type="submit" className="button1" style={{width:"30%"}}>Submit</button>
                        
                    </form>
                </div>
            </div>
      
    </Section>
  )
}

const Section=styled.div`
background-color: #0FA4AF;
  color: aliceblue;
  display: flex;
  height:100vh;
  
  padding: 20px;
    .login-frm{
        margin-top:"0px";
        width: 50%;
        height: 50vh;
        display: grid;
    }
    .login-frm form{
        
    }
    .check-box {
        width: 60%;
        padding: 10px;
        position: relative;
        left: 15%;
        color:#E09C2B;
        font-weight: bolder;
    }
   
`
export default ContactUs
