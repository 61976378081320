import React, { useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import ImageCarousel from './ImageCarousel';
import MyTimer from './timer';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';
import Footer from './Footer';
import Service from './HardwareCard';
import HardwareCard from './HardwareCard';
import Drop from './Drop';
import Chatbot from './Chatbot';
import ServiceList from './ServiceList';
import LoveUs from './LoveUs';

const Home = () => {
  const [selectedOption, setSelectedOption] = useState('');

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };
  return (
    <div style={{backgroundColor:"#f9f9f9"}}>
      {/* <Chatbot/> */}
      <CarouselSection>  
       <ImageCarousel/> {/* Your carousel component with slogans here */}
       
       <MyTimer style={{zIndex:""}}/>
      </CarouselSection>
      <LoveUs/>
      <section id="book"> <Drop/></section>
      
      
      
      {/* <section id="about-us"> <AboutUs/></section> */}
      
        {/* Add your content for the About Us section */}
      
      
        {/* Add your service request form here */}
        {/* <h3 className="mystic-content heading ">Services</h3> */}
        {/* <div id="services" style={{display:'flex'}}>
          
        <HardwareCard
        title="Hardware Polishing"
        description="We specialize in high-quality hardware polishing services to enhance the appearance and durability of your products."
        icon="/path/to/polishing-icon.png"  // Replace with the actual path to your polishing icon
        backgroundColor="#ffcccb"  // Customize the background color
      />
      <HardwareCard
        
        title="Brass Item Manufacturing"
        description="Explore our range of finely crafted brass items. We take pride in manufacturing exquisite brass products tailored to your specifications."
        icon="/path/to/brass-icon.png"  // Replace with the actual path to your brass icon
        backgroundColor="#ffd700"  // Customize the background color
        
      />
        </div> */}
        
        <section id="ServiceList"><ServiceList/></section>
        <section id="contact"> <ContactUs></ContactUs></section>
    </div>
  );
};

const CarouselSection = styled.section`
  /* Add styles for the carousel section */
`;

const AboutUsSection = styled.section`
  background-color: #f9f9f9; /* Example background color */
  padding: 20px;
  /* Add styles for the About Us section */
`;

const ServiceFormSection = styled.section`
  /* Add styles for the service request form section */
`;

export default Home;